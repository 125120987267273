/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
}

#p5-app {
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "PixelEmulator";
  src: url("./fonts/pixel_emulator.otf") format("opentype");
}

.navbar {
  /* font-family: 'PixelEmulator'; */
  font-size: 12px;
}

.dropdown-item {
  font-size: 12px;
}

.header {
  /* font-family: 'PixelEmulator'; */
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
  font-weight: normal;
}