.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.EventCard {
  flex: 0 0 auto;
  margin-right: 3px;
  width: 200px;
  min-height: 300px;
  background-color: #555;
  display: inline-block;
}

#map {
  height: 300px;
}

html, body {
  min-height: 100%;
}

/* Google Form - Narrow Screen Responsiveness

Incorporating a new google form: 
  Copy the "proposals-form-iframe-wrapper" class and paste it within the same @media query just below it, rename the new class appropriately. Only edit the "--max-form-height" variable. Edit its value while the browser width is around 320px, play with it until you are happy with the empty space below the form, and then make sure it scales well between 320-650px.

Note:
  This simulates "fit-content" for google forms as the screen narrows below 650px. Fit-content can't used on google forms because you cannot retrieve an iframe's height data if you don't have access to the source domain(i.e. google), attempting to get iframe height will throw a cross-origin access error.

*/
@media (max-width: 650px) {
  .proposals-form-iframe-wrapper {

    --max-form-height: 7000;

    /* This will allow the child iframe to expand to fill this parent wrapper vertically */
    display: flex;
    flex-direction: column;

    /* Do not edit */
    min-height: calc(((var(--max-form-height) * 1px) * 0.7)  + 3em); /* 3em is to compensate for the rest of the page ui that scales with browser font-size. */
    height: calc((var(--max-form-height) * 1px) - calc(var(--max-form-height * 1vw) / 12)) !important; /* "fit-content" */
  }
}
